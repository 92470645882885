/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import AppearOnScroll from "~components/AppearOnScroll";
import CustomerReview from "~components/CustomerReview";
import Footer from "~components/Footer";
import GivePaireCTA from "~components/GivePaireCTA";
import Layout from "~components/Layout";
import ProductGrid from "~components/ProductGrid";
import ProductOverview from "~components/ProductOverview";
import SEO from "~components/SEO";

const CustomPage = ({ data, location }) => {
  const cms = data.sanityCustomPage;

  //

  return (
    <>
      <SEO customTitle={cms.title} path={location.pathname} />

      <Layout className="custom-page w-full relative">
        {cms?.sections?.[0] &&
          cms.sections.map((section, sectionIndex) => {
            const sectionKey = `section-${sectionIndex}`;

            let sectionJSX;

            // eslint-disable-next-line no-underscore-dangle
            switch (section._type) {
              case `articleSection`:
                sectionJSX = (
                  <ProductOverview
                    image={section.image.asset.fluid}
                    imageAlt={section.image.altText}
                    heading={section.heading}
                    subheading={section.subheading}
                    text={section.content}
                    position={section.position}
                    backgroundColor={section.backgroundColor}
                  />
                );
                break;

              case `featureCardSection`:
                sectionJSX = (
                  <div className="featured-categories grid pt-v2 pb-v2">
                    {section.featureCards.map(
                      (featureCard, featureCardIndex) => {
                        const key = `${sectionKey}-feature-card-${featureCardIndex}`;

                        return (
                          <div
                            key={key}
                            className="index-page__card grid-end-4 relative overflow-hidden rounded-edge text-off-white text-center"
                          >
                            <AppearOnScroll className="w-full h-full relative">
                              {({ visible }) => {
                                const showImage =
                                  visible &&
                                  featureCard?.image?.asset?.fluid?.src;

                                return (
                                  <>
                                    {showImage && (
                                      <header
                                        className="animation-appear-left-slow w-full absolute top-0 right-0 left-0 z-10"
                                        style={{
                                          animationDelay: `${featureCardIndex *
                                            150 +
                                            300}ms`
                                        }}
                                      >
                                        <h4 className="mt-8 caption italic uppercase">
                                          {featureCard.subHeading}
                                        </h4>
                                        <h3 className="mt-2 f2">
                                          {featureCard.heading}
                                        </h3>
                                      </header>
                                    )}

                                    {showImage && (
                                      <figure
                                        className="animation-appear-right-slow w-full h-full relative"
                                        style={{
                                          animationDelay: `${featureCardIndex *
                                            150 +
                                            300}ms`
                                        }}
                                      >
                                        <Img
                                          className="w-full h-full relative block object-cover"
                                          fluid={featureCard.image.asset.fluid}
                                          alt={featureCard.image.altText}
                                        />
                                      </figure>
                                    )}
                                  </>
                                );
                              }}
                            </AppearOnScroll>
                          </div>
                        );
                      }
                    )}
                  </div>
                );

                break;

              case `imageSection`:
                sectionJSX = (
                  <div className="sixteen-by-nine relative">
                    <figure className="w-full h-full absolute top-0 right-0 bottom-0 left-0">
                      <Img
                        className="w-full h-full relative block object-cover"
                        fluid={section.image.asset.fluid}
                        alt={section.image.altText}
                      />
                    </figure>
                  </div>
                );
                break;

              case `givePaireSection`:
                sectionJSX = (
                  <GivePaireCTA
                    heading={section.heading}
                    subheading={section.subheading}
                    content={section.content}
                    image={section.image}
                  />
                );
                break;

              case `productGrid`:
                sectionJSX = (
                  <ProductGrid
                    heading={section.heading}
                    subheading={section.subheading}
                  />
                );
                break;

              case `reviewsSection`:
                sectionJSX = <CustomerReview reviews={section.review} />;
                break;

              default:
                break;
            }

            return (
              <section key={sectionKey} id={sectionKey}>
                {sectionJSX}
              </section>
            );
          })}
      </Layout>

      <Footer />
    </>
  );
};

export default CustomPage;

export const query = graphql`
  query CustomPage($id: String!) {
    sanityCustomPage(id: { eq: $id }) {
      title

      sections {
        ... on SanityArticleSection {
          _type
          heading
          subheading
          content
          backgroundColor
          position
          image {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
            altText
          }
        }

        ... on SanityFeatureCardSection {
          _type
          featureCards {
            heading
            subHeading
            image {
              asset {
                fluid {
                  ...GatsbySanityImageFluid
                }
              }
              altText
            }
          }
        }

        ... on SanityGivePaireSection {
          _type
          subheading
          heading
          content
          image {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
            altText
          }
        }

        ... on SanityImageSection {
          _type
          image {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
            altText
          }
        }

        ... on SanityProductGrid {
          _type
          subheading
          heading
        }

        ... on SanityReviewsSection {
          _type
          review {
            customer
            text
          }
        }
      }
    }
  }
`;
