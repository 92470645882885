import React from "react";
import Img from "gatsby-image";
import { graphql, Link, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import Button from "~components/Button";

const ProductGrid = ({ heading, subheading, max }) => {
  const { sanityProductGrid, allSanityProduct } = useStaticQuery(graphql`
    query ProductGrid {
      sanityProductGrid {
        heading
        subheading
      }

      allSanityProduct {
        edges {
          node {
            title
            handle
            priority
            frontPage
            tagline
            image {
              asset {
                fluid(maxWidth: 1024) {
                  ...GatsbySanityImageFluid
                }
              }
            }

            shopifyProduct {
              id
              title
              description
              handle
              images {
                originalSrc
              }
              productType
              vendor
              variants {
                id
                title
                image {
                  originalSrc
                }
                price
                selectedOptions {
                  name
                  value
                }
              }
            }
          }
        }
      }
    }
  `);

  //

  const products = [];

  let productCount = 0;

  allSanityProduct.edges.forEach(({ node }) => {
    if (productCount >= max) {
      return;
    }

    if (node?.frontPage) {
      products.push(node);
      productCount += 1;
    }
  });

  products.sort((a, b) => {
    const priorityA = a.priority;
    const priorityB = b.priority;

    if (priorityA < priorityB) {
      return -1;
    }

    if (priorityA > priorityB) {
      return 1;
    }

    return 0;
  });

  return (
    <section id="shop-now" className="w-full relative pt-v4 pb-v4 xs:py-10">
      <header className="grid" style={{ justifyItems: `center` }}>
        {(heading || sanityProductGrid?.heading) && (
          <h3 className="grid-end-12 f3 text-center xs:w-5/6">
            {heading || sanityProductGrid.heading}
          </h3>
        )}

        {(subheading || sanityProductGrid?.subheading) && (
          <h3 className="grid-end-6 xs:grid-end-12 grid-start-4 xs:grid-start-1 mt-v1 xs:mt-v4 xs:mb-v3 whitespace-pre-wrap b2 text-center">
            {subheading || sanityProductGrid.subheading}
          </h3>
        )}
      </header>

      {products?.[0] && (
        <ul className="grid gap-col-2 mt-v4 xs:gap-row-2">
          {products.map(product => {
            let image = `/uploads/sample.jpg`;
            let imageType = `default`;

            if (product?.image?.asset?.fluid) {
              image = product.image.asset.fluid;
              imageType = `fluid`;
            } else if (product?.images?.[1]?.originalSrc) {
              image = product?.images?.[1]?.originalSrc;
            }

            return (
              <li
                key={product.handle}
                className="product-grid__item grid-end-4 xs:grid-end-12 xs:mb-12"
              >
                <Link
                  to={`/products/${product.handle}`}
                  className="product-grid__item__image relative block rounded-edge overflow-hidden"
                >
                  {(imageType === `fluid` && (
                    <figure className="w-full h-full absolute top-0 right-0 bottom-0 left-0 gpu">
                      <Img
                        className="w-full h-full relative object-cover"
                        fluid={image}
                        alt={product.handle}
                      />
                    </figure>
                  )) || (
                    <img
                      className="w-full absolute transform-center"
                      src={image}
                      alt={product.handle}
                    />
                  )}
                </Link>

                <article className="mt-v05 xs:mt-5">
                  <header className="w-full relative flex items-center justify-between pt-v1">
                    <h2 className="b1">{product.title}</h2>
                  </header>

                  <p className="w-full mt-v05 b2 xs:mt-2">{product.tagline}</p>

                  <div className="mt-v2 xs:mt-4">
                    <Link to={`/products/${product.handle}`}>
                      <Button
                        className="w-full relative block xs:py-2"
                        color="hit-pink"
                        transparent
                      >
                        <span className="f-button">Shop Now</span>
                      </Button>
                    </Link>
                  </div>
                </article>
              </li>
            );
          })}
        </ul>
      )}
    </section>
  );
};

ProductGrid.defaultProps = {
  heading: null,
  subheading: null,
  max: 3
};

ProductGrid.propTypes = {
  heading: PropTypes.string,
  subheading: PropTypes.string,
  max: PropTypes.number
};

export default ProductGrid;
