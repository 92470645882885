/* eslint-disable react/prop-types */

import React, { useContext } from "react";
import Carousel from "~components/Carousel";
import { DocumentContext } from "~context/DocumentContext";
import Star from "~components/svg/Star";

const CustomerReview = ({ heading, reviews }) => {
  const { device } = useContext(DocumentContext);

  if (!reviews?.[0]) {
    return <></>;
  }

  if (device === `desktop`) {
    return (
      <div className="customer-review pt-v6 pb-v8">
        <div className="grid">
          <h2 className="f3 grid-end-12 text-center">
            {heading || `“Feels like stepping on clouds”`}
          </h2>
        </div>

        <ul className="customer-review__grid grid mt-v4">
          {reviews.map(review => (
            // eslint-disable-next-line no-underscore-dangle
            <li key={review._key} className="grid-end-4 text-center">
              <div className="w-full flex items-center justify-center">
                {Array(5)
                  .fill(null)
                  .map((_, index) => (
                    <Star key={`${review._key}-${index}`} className="w-v1" />
                  ))}
              </div>

              <p className="mt-v1 b1">“{review.text}”</p>
              <h4 className="b2 mt-5">- {review.customer}</h4>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  return (
    <div className="customer-review pt-v8 pb-v8 px-v2 w-full">
      <h2 className="f3 grid-end-12 text-center mb-10">
        “Feels like stepping on clouds”
      </h2>

      <Carousel
        keyPrefix="customer-review-carousel"
        items={reviews.map((review, index) => {
          const key = `review-${index}`;

          return (
            <div
              className="w-full relative text-center flex flex-col items-center"
              key={key}
            >
              <p className="b1">“{review.text}”</p>
              <h4 className="b2 mt-5">- {review.customer}</h4>
            </div>
          );
        })}
        withBullets
      />
    </div>
  );
};

export default CustomerReview;
